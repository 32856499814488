/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Buttons } from './BuyModal'
import { SecondaryButton, PrimaryButton } from '../../../components/Button'
import { Modal, CustomModalProps } from '../../../components/modal/Modal'
import { FileInput, Input, Textarea } from '../../../components/Input'
import { Block } from '../../../components/Block'
import { useForm } from 'react-hook-form'
import { InputErrors } from '../../../constants'

export const CreateCollectionModal = (props: CustomModalProps) => {
  const { register, handleSubmit, formState: { errors, isValid }, reset, control } = useForm({ mode: 'onSubmit' })

  const defaultValues = {
    name: '',
    description: '',
    comission: '',
    image: '',
  }

  const onSubmit = (data: any) => {
    const formData = new FormData()
    formData.append('image', data.image[0])
    reset(defaultValues)
  }

  return (
    <Modal title='Создание коллекции' close={props.close}>
      <Block marginTop={35} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label='Название'
          {...register('name', { required: InputErrors.isRequired })}
          placeholder='Абстрактная картина'
          error={errors?.name && errors.name.message}
        />
        <Textarea
          label='Описание'
          {...register('description', { required: InputErrors.isRequired })}
          placeholder='Длинное описание NFT, для отображения на странице NFT. Пользователь рассказвает о картинке.'
          error={errors?.description && errors.description.message}
        />
        <FileInput
          {...register('image')}
          text='Загрузите изображение в формате JPG или PNG размером не более 10 мегабайт'
        />
        <Input
          label='Комиссия автора, %'
          {...register('comission', {
            required: InputErrors.isRequired,
          })}
          error={errors?.comission && errors.comission.message}
          placeholder='Укажите комиссию автора'
        />
        <Buttons>
          <SecondaryButton onClick={props.close}>Отменить</SecondaryButton>
          <PrimaryButton type='submit'>Создать</PrimaryButton>
        </Buttons>
      </form>
    </Modal>
  )
}