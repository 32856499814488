/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Block } from '../../../../components/Block'
import { CenteredButtonsWrapper, GoUpButton, PrimaryButton, SecondaryButton } from '../../../../components/Button'
import { DefaultCard, CardTitle, CardInfo, CollectionCardsWrapper, CardInner, CardsLoader, blink } from '../../../../components/Card'
import { SearchInput } from '../../../../components/Search'
import { Collection, CollectionItem } from '../../../../types'
import routes from '../../../../routes'
import { useNavigate, useParams } from 'react-router-dom'
import ModalService from '../../../../components/modal/modal-service'
import { BuyModal } from '../../modals/BuyModal'
import { kitcut } from '../../../../utils'
import useStores from '../../../../hooks/useStores'
import Fuse from 'fuse.js'
import { devices } from '../../../../components/common/theme'
import akbarsBanner from '../../../../resources/images/akbars-banner.png'
import noize from '../../../../resources/images/noise.png'
import throttle from 'lodash.throttle'

export const addBuyModal = (itemData: CollectionItem, hideButton?: boolean) => {
  ModalService.open(({ close }) => <BuyModal {...itemData} close={close} hideButton={hideButton} />)
}

const BuyPageWrapper = styled.div`
  position: relative;
  z-index: 1;
`

const NoiseBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 900px;
  background: url(${noize});
  z-index: 0;
`

const BannerImageWrapper = styled.div`
  width: 450px;
  height: 450px;
  background-origin: border-box;
  background-size: 100% auto;
  background-position: center;
  background-image: url(${akbarsBanner});
  background-repeat: no-repeat;

  @media (max-width: 1220px) {
    width: 300px;
    height: 300px;
  }

  @media ${devices.mobile} {
    width: 200px;
    height: 200px;
  }
`

const BannerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 60px 0;

  @media ${devices.tablet} {
    margin: 20px 20px 0;
  }

  @media ${devices.mobile} {
    margin: 0;
    flex-direction: column-reverse;
  }
`

const BannerInner = styled.div`
  width: 50%;

  @media ${devices.mobile} {
    margin-top: 20px;
    width: 100%;
    max-width: 300px;
    text-align: center;
  }
`

const BannerTitle = styled.h1`
  margin: 0 auto;
  font-size: 42px;

  @media (max-width: 1220px) {
    font-size: 32px;
  }

  @media ${devices.tablet} {
    font-size: 28px;
  }

  @media ${devices.mobile} {
    font-size: 22px;
  }
`

const BannerButtons = styled.div`
  margin: 40px auto;

  @media ${devices.mobile} and (orientation: landscape)  {
    width: 100%;
    margin: 20px auto;
  } 

  @media ${devices.mobile} and (orientation: portrait) {
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
  } 
`

const CardLogoWrapper = styled.div<{imgUrl: string}>`
  background-image: ${({ imgUrl }) => `url(${imgUrl})`};
  background-size: cover;
  width: 130px;
  height: 46px;
  /* border-radius: 50%; */
  position: absolute;
  z-index: 2;
  top: -9%;
  left: 10%;

  @media ${devices.mobile} {
    left: 7%;
    top: -8%;
    width: 108px;
    height: 38px;
  } 
`

const LoadMoreButton = styled(SecondaryButton)<{fetching: boolean}>`
  ${({ fetching }) => fetching && css`
      animation: ${blink} 1s ease-in-out infinite;
      background-color: rgba(40, 47, 66, 1);
  `}
`

const Banner = () => {
  const { authStore } = useStores()
  const { isLoggedIn } = authStore
  const navigate = useNavigate()
  return <BannerWrapper>
    <BannerInner>
      <BannerTitle>Познакомиться с NFT-коллекцией &quot;Всероссийский СХ-хакатон 2022&quot;</BannerTitle>
      <BannerButtons>
        {isLoggedIn ? <PrimaryButton onClick={() => navigate(routes.qrCode)}>Получить NFT</PrimaryButton> :
          <PrimaryButton onClick={() => navigate(routes.signIn)}>Регистрация</PrimaryButton>
        }
      </BannerButtons>
    </BannerInner>
    <BannerImageWrapper />
  </BannerWrapper>
}

const CollectionItemCard = (props: CollectionItem) => {
  const navigate = useNavigate()
  const { api } = useStores()

  const onClickView = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, itemData: CollectionItem) => {
    e.stopPropagation()
    navigate(`/${routes.tokens}/${itemData.id}`)
  }

  return <DefaultCard
    key={props.id}
    imgUrl={props.videoUrl}
    isLandscapeOriented
  >
    <CardLogoWrapper imgUrl={props.collection.authorIcon} />
    <Block marginTop={20} />
    {/* <Link onClick={onLinkClick}>{props.author}</Link> */}
    <CardInner>
      <CardTitle>{props.name}</CardTitle>
      <CardInfo>{props.description && kitcut(props.description)}</CardInfo>
      {/* <SecondaryButton isSmall disabled={true}>Купить</SecondaryButton> */}
      <PrimaryButton isWide onClick={(e) => onClickView(e, props)}>Посмотреть</PrimaryButton>
    </CardInner>
  </DefaultCard>

}

export const BuyPage = () => {
  const { api } = useStores()
  const [tokens, setTokens] = useState<CollectionItem[]>([])
  const [showUpButton, setShowUpButton] = useState(false)

  const handleScroll = () => {
    setShowUpButton(window.pageYOffset > 100)
  }

  const throttledHandleScroll = throttle(handleScroll, 1000)

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [])

  useEffect(() => {
    const fetchTokens = async () => {
      const [{ data }, [collection]] = await Promise.all([api.getTokens(), api.getCollections()])
      const tokensToSet = data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          videoUrl: item.small,
          description: item.description,
          collection: { ...collection },
        }
      })
      setTokens(tokensToSet)
    }
    fetchTokens()
  }, [])

  return <>
    <NoiseBg />
    <BuyPageWrapper>
      <Banner />
      <Block marginTop={150} mobileMarginTop={50} />
      <CollectionCardsWrapper>
        { !!tokens.length && tokens.map((tokenData, i) => (
          <CollectionItemCard
            key={i}
            {...tokenData}
          />),
        )
        }
      </CollectionCardsWrapper>
      <GoUpButton className={showUpButton ? 'show' : 'hide'} />
      <Block marginTop={150} mobileMarginTop={50} />
    </BuyPageWrapper>
  </>
}
