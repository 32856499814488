/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Block } from '../../../components/Block'
import { PrimaryButton, SecondaryButton } from '../../../components/Button'
import { FileInput, Input, Textarea } from '../../../components/Input'
import { MultiSelect, SimpleSelect } from '../../../components/Select'
import { CreateCollectionModal } from '../modals/CreateCollectionModal'
import ModalService from '../../../components/modal/modal-service'
import { useForm, Controller } from 'react-hook-form'
import { InputErrors } from '../../../constants'
import useStores from '../../../hooks/useStores'
import { toast } from 'react-toastify'
import { Notification } from '../../../components/Notifications'

const Wrapper = styled.div`
  width: 814px;
  margin: 71px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.div`
  font-size: 56px;
  font-weight: 500;
`

const PriceInputWrapper = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const WestWrapper = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`

const InfoBlock = styled.div`
  font-weight: 700;
  opacity: 50%;
`

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SelectInner = styled.div`
  width: 70%;
`

const Button = styled(SecondaryButton)`
  margin-bottom: 0.7rem;
  padding: 0.7rem;
`

const addCreateCollectionModal = () => {
  ModalService.open(({ close }) => <CreateCollectionModal close={close} />)
}

export const Create = () => {
  const { api } = useStores()
  const { register, handleSubmit, formState: { errors, isValid }, reset, control, resetField, setValue } = useForm({ mode: 'onSubmit' })
  const [isDeleteFile, setIsDeleteFile] = useState(false)
  const options = [
    { value: 'art', label: 'Искусство' },
    { value: 'awards', label: 'Награды' },
    { value: 'art2', label: 'Aрт' },
    { value: 'photos', label: 'Фотографии' },
  ]

  const defaultValues = {
    name: '',
    description: '',
    tags: '',
    collection: '',
    price: '',
    image: '',
    code: Math.random().toString(36),
  }
  // init code
  setValue('code', Math.random().toString(36))

  const resetValues = () => {
    setIsDeleteFile(true)
    reset(defaultValues)
  }

  const onSubmit = async (data: any) => {
    const formData = new FormData()
    formData.append('image', data.image[0])
    try {
      const { id: imageId } = await api.uploadImage(data.image[0])
      const response = {
        code: data.code,
        name: data.name,
        description: data.description,
        externalUrl: 'test.test/test.wow',
        collectionId: '0e3157b3-823c-458d-a652-250bf7d368fe',
        price: Number(data.price),
        attributes: {},
        image: imageId,
      }
      const { id: tokenId } = await api.createToken(response)
      for (const tag of data.tags) {
        await api.createTag(tokenId, tag.value)
      }
      toast(<Notification message={'Token created'} />, { hideProgressBar: true, autoClose: 5000 })
      resetValues()
    } catch (e: any) {
      console.error('Error create token:', e)
      toast(<Notification isError message={e.response.data.error || e.response.data.message || e.message || 'error'} />, { hideProgressBar: true, autoClose: 5000 })
    }
  }

  return <Wrapper>
    <Title>Создать NFT</Title>
    <Block marginTop={50} />
    <form onSubmit={handleSubmit(onSubmit)}>
      <FileInput
        {...register('image')}
        text='Загрузите изображение в формате JPG или PNG размером не более 10 мегабайт'
        isDeleteFile={isDeleteFile}
      />
      <Input
        label='Название'
        {...register('name', { required: InputErrors.isRequired })}
        placeholder='Абстрактная картина'
        error={errors?.name && errors.name.message}
      />
      <Textarea
        label='Описание'
        {...register('description', { required: InputErrors.isRequired })}
        placeholder='Длинное описание NFT, для отображения на странице NFT. Пользователь рассказвает о картинке.'
        error={errors?.description && errors.description.message}
      />
      <Controller
        name="tags"
        control={control}
        rules={{ required: InputErrors.isRequired }}
        render={({ field: { onChange, value, ref } }) => (
          <MultiSelect
            values={value}
            inputRef={ref}
            label='Теги'
            options={options}
            onChange={onChange}
            error={errors?.tags && errors.tags.message}
          />
        )}
      />
      <SelectWrapper>
        <SelectInner>
          <Controller
            name="collection"
            control={control}
            rules={{ required: InputErrors.isRequired }}
            render={({ field: { onChange, value, ref } }) => (
              <SimpleSelect
                value={value}
                inputRef={ref}
                label='Коллекция'
                placeholder={'Выберите коллекцию или создайте новую'}
                options={options}
                onChange={onChange}
                error={errors?.collection && errors.collection.message}
              />
            )}
          />
        </SelectInner>
        <Button isSmall type='button' onClick={() => addCreateCollectionModal()}><b>+</b> Создать коллекцию</Button>
      </SelectWrapper>
      <Input
        label='Промокод (видите только вы)'
        {...register('code')}
        disabled
        name='code'
      />
      <PriceInputWrapper>
        <Input
          label='Цена'
          {...register('price', {
            required: InputErrors.isRequired,
          })}
          error={errors?.price && errors.price.message}
        />
        <WestWrapper>~ 1200 WEST</WestWrapper>
      </PriceInputWrapper>
      <InfoBlock>Комиссия за выпуск NFT: 2 WEST</InfoBlock>
      <Block marginTop={'1rem'}>
        <PrimaryButton type='submit'>Создать</PrimaryButton>
      </Block>
    </form>
  </Wrapper>
}