/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren, useEffect } from 'react'
import styled from 'styled-components'
import routes from '../../routes'
import { Tabs } from './components/common/Tabs'
import { Block } from '../../components/Block'
import { devices } from '../../components/common/theme'

const ContentWrapper = styled.div`
  box-sizing: border-box;
  background-image: linear-gradient(#2E3752, #0C101C);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: fit-content;
  width: 100vw;
  color: white;
  padding: 150px 0;

  @media ${devices.mobile} {
    padding: 50px 0;
  }
`
const ContentInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  font-family: PTSans, sans-serif;
  text-align: center;
  font-weight: 400;
  font-size: 48px;
  color: white;
  text-align: center;

  @media ${devices.mobile} {
    font-size: 32px;
    margin: 0 20px;
  }
`

const tabsItems = [{
  route: `/${routes.signIn}`,
  label: 'Войти',
}, {
  route: `/${routes.signUp}`,
  label: 'Регистрация',
}]

const Container = styled.div`
  width: 400px;
  margin: 0 auto;

  @media ${devices.mobile} {
    width: auto;
    min-width: 300px;
  }
`

type AuthRouteWrapperProps = {
  withTabs?: boolean,
  title?: string,
} & PropsWithChildren

export const AuthRouteWrapper = (props: AuthRouteWrapperProps) => {
  return <ContentWrapper>
    <ContentInner>
      <Title>{props.title || 'Добро пожаловать'}</Title>
      <Container>
        {props.withTabs && <>
          <Block marginTop={50} />
          <Tabs items={tabsItems} />
          <Block marginTop={50} />
        </>
        }
        {props.children}
      </Container>
    </ContentInner>
  </ContentWrapper>
}
