import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import searchImg from '../resources/images/search.png'
import { ITag } from '../types'
import { Tags } from './Tags'

type SearchInputProps = {
  placeholder: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
} & React.ComponentPropsWithoutRef<'input'>

type SearchInputWithTagsProps = {
  tags: ITag[],
  onChangeWithTags?: (input: string, tags: string[]) => void,
} & SearchInputProps

const Wrapper = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 1.3rem;
  padding-left: 5rem;
`

const SearchImg = styled.div`
  background-image: url(${searchImg});
  background-size: 28px;
  position: absolute;
  top: 0rem;
  left: 1.5rem;
  height: 27px;
  width: 28px;
  background-size: cover;
`

const Search = styled.input`
  margin: 0 auto;
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  font-size: 18px;

  ::focus {
    outline: none;
    border: none;
  }

  ::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-size: 19px;
  }
`

export const SearchInput = (props: SearchInputProps) => {
  return <Wrapper>
    <SearchImg />
    <Search type='text' placeholder={props.placeholder} onChange={props.onChange} />
  </Wrapper>
}

export const SearchInputWithTags = (props: SearchInputWithTagsProps) => {
  const [input, setInput] = useState('')
  const [tags, setTags] = useState<string[]>([])

  useEffect(() => {
    if (props.onChangeWithTags) {
      props.onChangeWithTags(input, tags)
    }
  }, [input, tags])

  const onChangeInput = (e: any) => {
    const input = e.target.value
    setInput(input)
  }

  return <>
    <SearchInput placeholder={props.placeholder} onChange={onChangeInput} value={input} />
    <Tags options={props.tags} onChange={setTags} />
  </>
}
